/* eslint-disable jsx-a11y/no-autofocus */
import PageMeta from '@components/PageMeta';
import TextField from '@components/TextField';
import { useAuth } from '@context/AuthContext';
import { AuthType } from '@helpers/AuthClient';
import useTranslation from '@helpers/useTranslation';
import AuthTemplate from '@templates/AuthTemplate';
import React, { FC, useState } from 'react';
import { Button, Content, Form, Message } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  email: string;
}

interface ResendVerifyEmailViewProps {
  authType: AuthType;
  defaultValues?: Partial<FormValues>;
}

const ResendVerifyEmailView: FC<ResendVerifyEmailViewProps> = ({ authType, defaultValues }) => {
  const { t } = useTranslation(['auth', 'validation', 'pages']);

  const { authClient } = useAuth();

  const formProps = useForm<FormValues>({ defaultValues });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleFormSubmit: SubmitHandler<FormValues> = ({ email }) => {
    setIsLoading(true);

    authClient
      .resendVerificationEmail(email, authType)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(error => {
        const errorMessage =
          error.message === 'tooManyAttempts' ? t('validation:tooManyAttempts') : error.message;

        formProps.setError('email', { message: errorMessage }, { shouldFocus: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthTemplate>
      <PageMeta description={t('pages:resendCodeDescription')} title={t('pages:resendCode')} />
      <AuthTemplate.Content>
        {isSuccess && (
          <Message color="success">
            <Message.Body>{t('verifyEmailEmailSent')}</Message.Body>
          </Message>
        )}
        <Content>
          <p>{t('resendVerificationEmailInstructions')}</p>
        </Content>
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
            <TextField<FormValues>
              autoComplete="email"
              autoFocus
              id="email"
              label={t('common:email')}
              name="email"
              type="email"
              validation={{ required: t('validation:required', { field: t('common:email') }) }}
            />
            <Form.Field alignItems="center" kind="group">
              <Form.Control>
                <Button color="primary" loading={isLoading}>
                  {t('common:submit')}
                </Button>
              </Form.Control>
            </Form.Field>
          </form>
        </FormProvider>
      </AuthTemplate.Content>
    </AuthTemplate>
  );
};

export default ResendVerifyEmailView;
