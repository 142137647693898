import { AuthType } from '@helpers/AuthClient';
import ResendVerifyEmailView from '@views/ResendVerifyEmailView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

interface LocationState {
  email?: string;
}

const ResendCodePage: FC<PageProps<null, null, LocationState | null>> = ({
  location: { state },
}) => {
  return <ResendVerifyEmailView authType={AuthType.Web} defaultValues={{ email: state?.email }} />;
};

export default ResendCodePage;
